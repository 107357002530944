import {
  CoreEditor,
  EditorState,
  getVersion,
  JSONContent,
  sendableSteps,
  Step,
} from "@vericus/cadmus-editor-prosemirror";

import { Channel } from "phoenix";
import { debounce } from "ts-debounce";

import {
  __GLOBAL_CLIENT_ID,
  __GLOBAL_TENANT,
  __GLOBAL_WORK_ID,
} from "@/client/globals";
import { API_ENDPOINT } from "@/config";

enum CommState {
  INIT,
  POLL,
  SEND,
}

// Constants
const SAVE_DEBOUNCE = 1000;

/**
 * Initialisation props for a StreamConnection on an Editor.
 */
export interface StreamConnectionProps {
  /** The editor that is wrapped and connected for collaboration. */
  editor: CoreEditor;
  /** Work context */
  workId: string;
  /** Collab Authority Stream UUID specific to the Editor instance. */
  streamId: string;
  /** Base state of the Stream or the Edtor that is considered version 0. */
  initialState: JSONContent;
  /** Phoenix Work Channel for the `workId`. */
  channel?: Channel;
  /** Callback to write a confirmed version snapshot. */
  onSnapshot?: (snapshot: { doc: JSONContent; version: number }) => void;
}

/**
 * Connect a TipTap `Editor` to a collaborative Authority's `Stream`.
 *
 * ## Authority Stream
 *
 * A `Stream` is an append-only data structure storing ordered
 * operation-transform `Step` steps. Steps can only be appended to the stream if
 * the client provided version matches, otherwise the client must first rebase
 * it's local state and catch up before trying a subsequent write.
 *
 * The `Editor` instance has a confirmed internal `version` number and a buffer
 * of un-confirmed steps (provided by the `collab` extension and plugin).
 *
 * Connecting the `Editor` to a backend Authority Stream will:
 *
 *  1. Load the initial state of the Editor by catching up to the latest steps.
 *
 *  2. Regularly listen to the Work Channel for new steps.
 *
 *  3. Send local steps to the Authority server to append to the stream and
 *     handle the re-basing on conflicts.
 *
 *  4. Generate snapshots for confirmed versions of the EditorState.
 *
 * ## Communication channels
 *
 * The `Editor` can be *connected* to Authority Stream via the REST API and a
 * WebSocket channel. The REST API is used for transactional and stateless
 * operations for writing and reading a Stream. The WebSocket channel is used
 * for eagerly listening to writes from other clients and syncing cursors for
 * visual fidelity.
 *
 * NOTE: The absense of the websocket connection should not have a critical
 * impact as the transactional nature for the REST API will assist the Editor in
 * catching up on conflicts.
 *
 * The Cadmus Authority REST API exposes the following endpoints:
 *
 *   1. `AppendSteps` - Append steps on a Stream at the expected version.
 *   Returns 204 accepted or 409 conflict.
 *
 *   2. `ReadSteps` - Read steps from a version.
 *
 * The WebSocket channel uses an existing `Phoenix.Channel` connected to a
 * Pantheon Work topic.
 *
 * Channel listens to the messages:
 *
 *   1. `editor.version` with payload `EditorVersionMsg`.
 *
 * Channel pushes the messages:
 *
 *   1. `editor.selection` with payload `EditorSelectionMsg`.
 *
 * ## Usage
 *
 * Initialise the connection instance:
 *
 *     const editor = createCadmusEditor({ ...props })
 *     const conn = new StreamConnection({ editor, ...props });
 *
 * Asynchronously await the connection:
 *
 *     await conn.connect();
 *
 * The `connect()` is asynchronously and **must** be awaited to ensure the
 * `Editor` is ready for interaction.
 */
export class StreamConnection {
  editor: CoreEditor;
  workId: string;
  streamId: string;

  private channel?: Channel;
  private onSnapshot?: (snapshot: {
    doc: JSONContent;
    version: number;
  }) => void;

  private initialState: JSONContent;
  private comm: CommState = CommState.INIT;
  private listenRef: number | undefined = undefined;

  constructor(props: StreamConnectionProps) {
    this.editor = props.editor;
    this.workId = props.workId;
    this.streamId = props.streamId;
    this.channel = props.channel;
    this.onSnapshot = props.onSnapshot;
    this.initialState = props.initialState;
  }

  /**
   * Setup the connection and start collaboration.
   *
   * Ensures that the Stream is ready and the Editor is caught up to the latest
   * confirmed version before interaction can begin.
   */
  connect = async () => {
    // Ensure the Stream is created in the backend
    await setupAuthorityStream(this.streamId, this.initialState);

    // Save local changes
    const debouncedSendSteps = debounce(this.sendSteps, SAVE_DEBOUNCE, {
      maxWait: SAVE_DEBOUNCE * 4,
    });
    this.editor.on("update", debouncedSendSteps);

    // Cursor sync on focus
    this.editor.on("focus", () => {
      this.sendCursor();
    });

    // And cleanup when done
    this.editor.on("destroy", () => {
      this.clean();
    });

    // Begin by catching up to the latest state.
    await this.poll();

    const sendable = sendableSteps(this.editor.state);
    // eslint-disable-next-line no-console
    console.assert(
      sendable === null,
      "Sendable steps should be empty after load",
      sendable
    );

    // Listen to changes
    this.listen();
  };

  private receiveSteps = (
    steps: readonly Step[],
    clientIds: string[],
    version: number,
    onNewState?: (state: EditorState) => void
  ): boolean => {
    if (steps.length === 0) return true;
    if (getVersion(this.editor.state) !== version) {
      console.error(
        "Received steps but editor has moved on from version",
        version
      );
      return false;
    }
    this.editor.commands.receiveSteps(steps, clientIds, onNewState);
    // Cursor after steps have been received
    this.sendCursor();
    return true;
  };

  private poll = async () => {
    const { steps, version } = await readSteps(
      this.streamId,
      getVersion(this.editor.state)
    );

    if (this.comm === CommState.SEND) return;

    this.receiveSteps(
      steps.map((s) => Step.fromJSON(this.editor.schema, s.data)),
      steps.map((s) => s.client_id),
      version
    );
  };

  private listen = async () => {
    if (this.channel) {
      this.listenRef = this.channel.on(
        "editor.version",
        (payload: EditorVersionMsg) => {
          if (
            payload.stream_id === this.streamId &&
            payload.version > getVersion(this.editor.state)
          ) {
            this.poll();
          }
        }
      );
    }
  };

  private sendSteps = async () => {
    const sendable = sendableSteps(this.editor.state);
    if (sendable) {
      this.comm = CommState.SEND;

      const transaction = {
        version: sendable.version,
        steps: sendable.steps,
        client_id: sendable.clientID.toString(),
      };

      const result = await appendSteps(this.streamId, this.workId, transaction);
      if (result.status === "ok") {
        const handled = this.receiveSteps(
          transaction.steps,
          transaction.steps.map(() => transaction.client_id),
          transaction.version,
          (state) =>
            this.onSnapshot?.({
              doc: state.doc.toJSON(),
              version: getVersion(state),
            })
        );
        if (!handled) {
          this.sendSteps();
        }
        this.comm = CommState.POLL;
      }
      if (result.status === "conflict") {
        this.comm = CommState.POLL;
        await this.poll();
      }
      if (result.status === "ok") {
        // Cursor after steps have been written
        this.sendCursor();
      }
    }
  };

  private sendCursor = () => {
    if (this.channel) {
      this.channel.push("editor.selection", {
        stream_id: this.streamId,
        client_id: __GLOBAL_CLIENT_ID.current,
        selection: this.editor.state.selection.toJSON(),
      } satisfies EditorSelectionMsg);
    }
  };

  private clean = () => {
    if (this.listenRef) {
      this.channel?.off("editor.version", this.listenRef);
    }
  };
}

// Payload for `editor.version` channel message.
interface EditorVersionMsg {
  stream_id: string;
  version: number;
}

// Payload for `editor.selection` channel message.
interface EditorSelectionMsg {
  stream_id: string;
  client_id: string;
  selection: object;
}

//////////////////////////////////////////////////////////////////////////////
// HTTP API                                                                 //
//////////////////////////////////////////////////////////////////////////////

interface Transaction {
  version: number;
  steps: readonly Step[];
  client_id: string;
}

interface AppendResult {
  status: "ok" | "conflict" | "error";
}

interface ReadStepsResult {
  steps: ServerStep[];
  version: number;
}

interface ServerStep {
  /** Step Data */
  data: unknown;
  /** Client ID that sent the step */
  client_id: string;
}

interface Stream {
  stream_id: string;
  version: number;
  initial_state: JSONContent;
}

/**
 * Ensure a Stream is setup for an Answer Block.
 *
 * @param answerBlockId - The Answer Block ID to use as the Stream ID.
 * @param initialState - The initial state of the Stream at version 0.
 * @returns Promise that resolves to the Stream object.
 *
 * @throws Error if the Stream could not be created. In this case it's better to
 *     reload the application.
 */
async function setupAuthorityStream(
  streamId: string,
  initialState: JSONContent
): Promise<Stream> {
  const headers = new Headers({
    "x-cadmus-role": "student",
    "x-cadmus-tenant": __GLOBAL_TENANT.current || "",
    "Content-Type": "application/json",
    Accept: "application/json",
  });

  const resp = await fetch(`${API_ENDPOINT}/api/authority/streams`, {
    method: "POST",
    headers,
    credentials: "include",
    body: JSON.stringify({
      stream_id: streamId,
      initial_state: initialState,
    }),
  });

  if (resp.ok) {
    const stream: Stream = await resp.json();
    return stream;
  }

  throw new Error("StreamCreationError");
}

async function appendSteps(
  streamId: string,
  workId: string,
  transaction: Transaction
): Promise<AppendResult> {
  const headers = new Headers({
    "x-cadmus-role": "student",
    "x-cadmus-tenant": __GLOBAL_TENANT.current || "",
    "Content-Type": "application/json",
  });

  const body = { transaction, work_id: workId };

  const resp = await fetch(
    `${API_ENDPOINT}/api/authority/transactions/${streamId}`,
    {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify(body),
    }
  );

  if (resp.ok && resp.status === 204) {
    return { status: "ok" };
  }

  if (resp.status === 409) {
    return { status: "conflict" };
  }

  return { status: "error" };
}

async function readSteps(
  streamId: string,
  version: number
): Promise<ReadStepsResult> {
  const headers = new Headers({
    "x-cadmus-role": "student",
    "x-cadmus-tenant": __GLOBAL_TENANT.current || "",
    Accept: "application/json",
  });

  const resp = await fetch(
    `${API_ENDPOINT}/api/authority/steps/${streamId}?version=${version}`,
    {
      method: "GET",
      headers,
      credentials: "include",
    }
  );

  if (resp.ok) {
    const result: ReadStepsResult = await resp.json();
    return result;
  }

  return { steps: [], version };
}
