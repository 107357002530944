import { __GLOBAL_BROWSER_STORAGE, __GLOBAL_TENANT } from "@/client/globals";
import { AppStartListening } from "@/data/listenerMiddleware";
import { AppDispatch } from "@/data/store";
import {
  selectAssessmentFormat,
  selectIsExam,
  selectIsLockDownExam,
  selectReferencingStyle,
} from "@/features/assignment";
import {
  hydrateSnapshots,
  selectSavingMechanism,
  selectSnapshots,
} from "@/features/authority";
import { BrowserStorageState } from "@/features/browser-storage";
import { TaskFormat } from "@/generated/graphql";
import { withTimeout } from "@/utils/with-timeout";

import { loadEditorStore } from "../editor-store";
import { Snapshot } from "../snapshot";

/**
 * Start listener for hydrating the answer store after all the snapshots are
 * loaded into the Redux store.
 *
 * isRecoveryCompleted - flag to make sure offline recovery is done only once in
 * tha app lifecycle
 */
export function startEditorStoreListener(startListening: AppStartListening) {
  let isRecoveryCompleted = false;

  startListening({
    actionCreator: hydrateSnapshots,
    effect: async (action, listenerApi) => {
      const { answers, assessmentName } = action.payload;
      const snapshots = selectSnapshots(listenerApi.getState());

      if (!isRecoveryCompleted) {
        await handleOfflineRecovery(snapshots, listenerApi.dispatch);
        isRecoveryCompleted = true;
      }

      const isLockDownExam = selectIsLockDownExam(listenerApi.getState());
      const isExam = selectIsExam(listenerApi.getState());
      const format = selectAssessmentFormat(listenerApi.getState());
      const isMultiFormat = format === TaskFormat.Multiformat;
      const referencingStyle = selectReferencingStyle(listenerApi.getState());
      const latestSnapshots = selectSnapshots(listenerApi.getState());
      const savingMechanism = selectSavingMechanism(listenerApi.getState());

      // making sure the editor store is loaded with the latest snapshots
      // (since offline recovery could have updated them)
      await loadEditorStore(answers, latestSnapshots, listenerApi.dispatch, {
        workId: action.payload.workId,
        isCollaborative: action.payload.isGroupWork,
        isExam,
        isMultiFormat,
        isLockDownExam,
        referencingStyle,
        assessmentName,
        savingMechanism,
      });
    },
  });
}

async function handleOfflineRecovery(
  snapshots: Record<string, Snapshot>,
  dispatch: AppDispatch
) {
  const storage = __GLOBAL_BROWSER_STORAGE.current;
  if (!storage) return;

  const initializeAndRecover = async () => {
    if (storage.getState() === BrowserStorageState.NOT_INITIALISED) {
      await storage.initialise();
    }
    await storage.recoverPendingSnapshots(snapshots, dispatch);
  };

  const TIMEOUT_MS = 10000;
  try {
    await withTimeout(initializeAndRecover(), TIMEOUT_MS);
  } catch (_error) {
    console.error("Browser Storage initialisation timed out");
    storage.disable();
  }
}
